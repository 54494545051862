import React, { FC } from "react";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    navLink: {
        color: "black",
        textDecoration: "none",
    },
});

type Props = {
    to: string;
    color?: string;
    as?: any;
    onClick?: () => void;
};

const NavButton: FC<Props> = (props) => {
    const classes = useStyles();
    const Cmp = props.as;
    return (
        <Cmp onClick={props.onClick}>
            <NavLink
                className={classes.navLink}
                color={props.color}
                to={props.to}
            >
                {props.children}
            </NavLink>
        </Cmp>
    );
};

NavButton.defaultProps = {
    color: "black",
    as: Button,
};

export default NavButton;
