import styled from "styled-components";
import { SmallQuery } from "../../../utils/responsive";
import { Button as MButton } from "@mui/material";

export const Button = styled(MButton)`
    @media ${SmallQuery} {
        font-size: 0.75rem;
    }
`;

export const EntryContainer = styled.div`
    width: 100%;
    text-align: center;
    margin: 1rem auto auto;

    ul {
        text-align: left;
        background-color: white !important;
    }
`;

export const DataTree = styled.div`
    width: 50%;
    border: 1px solid #003459;
    border-radius: 3px;
    padding: 10px;
    margin: 15px auto auto;
    background: white;

    @media ${SmallQuery} {
        width: 90%;
    }
`;
