import React, { useMemo } from "react";
import { Route, Routes } from "react-router";

import CssBaseline from "@mui/material/CssBaseline";

import Data from "../data/View";
import Home from "../home";
import { Account, Login, Signup } from "../auth";
import PrivateRoute from "../common/PrivateRoute";
import NavBar from "../navbar";
import "./style.css";
import { MaxUsage, Footer, AuthIsLoaded, ProAccount } from "./components";
import { useSelector } from "react-redux";
import { Profile, RootState } from "../../reducers";
import { isLoaded } from "react-redux-firebase";
import { Roles } from "../../types";
import "firebase/compat/auth";

const MAX_USAGE_HIDDEN = true;
const PRO_ACCOUNT_HIDDEN = false;

const App = () => {
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );
    const showUpgradeBanner = useMemo(
        () =>
            isLoaded(profile) && !!profile.email && profile.role !== Roles.PAID,
        [profile]
    );

    return (
        <>
            <CssBaseline />
            <div className="App">
                <div id="page-wrap">
                    <NavBar />
                    <MaxUsage hidden={MAX_USAGE_HIDDEN} />
                    {showUpgradeBanner && (
                        <ProAccount hidden={PRO_ACCOUNT_HIDDEN} />
                    )}
                    <AuthIsLoaded>
                        <Routes>
                            <Route index element={<Home />} />
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/data/:user"
                                element={
                                    <PrivateRoute>
                                        <Data />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/account"
                                element={
                                    <PrivateRoute>
                                        <Account />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="/signup" element={<Signup />} />
                        </Routes>
                    </AuthIsLoaded>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default App;
