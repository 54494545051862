import { createStore, compose, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";

import createRootReducer from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { createReduxHistoryContext } from "redux-first-history";

type InitialState = {};

const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
        history: createBrowserHistory(),
    });

export default function configureStore(initialState: InitialState) {
    const store = createStore(
        createRootReducer(routerReducer),
        initialState,
        compose(composeWithDevTools(applyMiddleware(routerMiddleware)))
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept("../reducers", () => {
            store.replaceReducer(createRootReducer(routerReducer));
        });
    }

    return store;
}

export const createHistory = createReduxHistory;
