import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import * as Sentry from "@sentry/browser";
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
} from "@mui/material/styles";
// @ts-ignore
import HttpsRedirect from "react-https-redirect";
import "firebase/auth";
import "firebase/compat/database";
import firebase from "firebase/compat/app";
import "./env";
import App from "./components/app";
import configureStore, { createHistory } from "./store/store";
import { firebase as firebaseConfig } from "./config";
import initializeApp = firebase.initializeApp;

const initialState = { firebase: { authError: null } };
const store = configureStore(initialState);
const history = createHistory(store);

initializeApp(firebaseConfig);

const rrfProps = {
    firebase,
    config: {
        userProfile: "users",
        profileParamsToPopulate: [
            { child: "role", root: "roles" }, // populates user's role with matching role object from roles
        ],
    },
    dispatch: store.dispatch,
};

Sentry.init({
    dsn: "https://a22e2d66984741dba29f5361251541c0@sentry.io/1848801",
    release: "madden-exporter-app@" + process.env.npm_package_version,
});

const theme = createTheme();

ReactDOM.render(
    <Provider store={store}>
        <HttpsRedirect>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <StyledEngineProvider injectFirst>
                    <Router history={history}>
                        <ThemeProvider theme={theme}>
                            <App />
                        </ThemeProvider>
                    </Router>
                </StyledEngineProvider>
            </ReactReduxFirebaseProvider>
        </HttpsRedirect>
    </Provider>,
    // @ts-ignore
    global.document.getElementById("root")
);
