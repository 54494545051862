import React, { FC } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type Props = {
    hidden: boolean;
};

const useStyles = makeStyles({
    warning: {
        background: "#1fa05c",
        textAlign: "center",
        borderTop: "1px solid #222b67",
        padding: "10px 0px",
        borderBottom: "1px solid #9e9e9e",
        fontSize: 14,
        fontWeight: 500,
        wordBreak: "break-word",
        color: "white",
    },
    warnText: { background: "#1fa05c", width: "80%", margin: "0 auto" },
    paragraph: { margin: 0 },
});

const MaxUsage: FC<Props> = ({ hidden }) => {
    const classes = useStyles();
    return (
        <Box hidden={hidden}>
            <div className={classes.warning}>
                <div className={classes.warnText}>
                    <p className={classes.paragraph}>
                        I purged the database of all data. We are now under the
                        limit. Thanks for you patience.
                    </p>
                    <p className={classes.paragraph}>
                        Exports are working again. Feel free to{" "}
                        <a
                            aria-label="You can donate to my paypal."
                            href="https://www.paypal.me/jbirdwell"
                        >
                            donate.
                        </a>
                    </p>
                </div>
            </div>
        </Box>
    );
};

export default MaxUsage;
