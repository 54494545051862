import React, { useState } from "react";
import { Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";

import "./style.css";
import SmallMenu from "./SmallMenu";
import FullMenu from "./FullMenu";
import { makeStyles } from "@mui/styles";
import { info } from "../../info";

const StyleAppBar = styled(AppBar)({
    background: info.color,
});

const useStyles = makeStyles({
    drawerIcon: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
    },
    home: { textDecoration: "none", color: "white" },
});

const MenuAppBar = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up("sm"));
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const toggleDrawer = (open: boolean) => {
        setOpen(open);
    };

    return (
        <div style={{ flexGrow: 1 }}>
            <StyleAppBar position="static" color="primary">
                <Toolbar>
                    <Typography
                        variant="h6"
                        color="inherit"
                        style={{ display: "flex" }}
                    >
                        <Link className={classes.home} to="/">
                            Madden Exporter
                        </Link>
                    </Typography>
                    {desktop ? (
                        <FullMenu />
                    ) : (
                        <div className={classes.drawerIcon}>
                            <MenuIcon onClick={() => toggleDrawer(true)} />
                        </div>
                    )}
                </Toolbar>
            </StyleAppBar>
            <Drawer
                anchor="bottom"
                open={open}
                onClose={() => toggleDrawer(false)}
            >
                <SmallMenu toggleDrawer={toggleDrawer} />
            </Drawer>
        </div>
    );
};

export default MenuAppBar;
