import React from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirebaseConnect } from "react-redux-firebase";
import { Link, NavLink, useParams } from "react-router-dom";

import "./View.css";
import Entry from "./components/Entry";
import DataLoading from "./components/DataLoading";
import styled from "styled-components";
import { Profile, RootState, Schema } from "../../reducers";
import { Leagues } from "../../types";
import { SmallQuery } from "../../utils/responsive";
import Button from "@mui/material/Button";

const Title = styled.p`
    @media ${SmallQuery} {
        text-align: left;
        margin-left: 20px;
        margin-right: 20px;
    }
`;

const Data = () => {
    const { user } = useParams<{ user: string }>();
    useFirebaseConnect([{ path: `/data/${user}` }]);
    const { data } = useSelector<RootState, Schema>(
        (state) => state.firebase.data
    );
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );

    if (isLoaded(profile) && !profile.username) {
        return (
            <div className="data">
                <Title>Add a username to be able to exporter data.</Title>
                <Button variant="contained">
                    <NavLink
                        className="add-username"
                        color="inherit"
                        to="/account"
                    >
                        Add Username
                    </NavLink>
                </Button>
            </div>
        );
    }

    if (!isLoaded(data) && !isLoaded(profile)) {
        return <DataLoading />;
    }
    const leagues: Leagues = data && data[profile.username];
    const leagueIds = (leagues && Object.keys(leagues)) || [];

    return (
        <div className="data">
            <div className="instruction">
                <Title>
                    Export from the companion app to{" "}
                    <strong>{`https://mxport.herokuapp.com/${
                        profile?.username || ""
                    }`}</strong>
                    . (Case Sensitive)
                </Title>
            </div>
            {Object.values(data || {})[0] === null && (
                <div className="empty">
                    Please export a league. Follow the steps on the{" "}
                    <Link className="homepage-link" to="/">
                        homepage
                    </Link>
                    .
                </div>
            )}
            {leagueIds.map((id) => (
                <Entry
                    id={id}
                    key={id}
                    league={leagues[id]}
                    profile={profile}
                />
            ))}
        </div>
    );
};

export default Data;
