import JSZip from "jszip";
import { saveAs } from "file-saver";
import { v4 } from "uuid";

import { League } from "../../../types";
import { getRoster, removeFalsy } from "./utils";

export const downloadJSON = async (league: League) => {
    if (!league) return;

    const zip = new JSZip();
    const leagueKeys = Object.keys(league) || [];

    // @ts-ignore
    global?.analytics?.track("JSON Download");

    leagueKeys.forEach((key) => {
        // @ts-ignore
        const dataString = JSON.stringify(removeFalsy(league[key]), 0, 4);
        const blob = new Blob([dataString], {
            type: "text/json;charset=utf-8;",
        });
        zip.file(`${key}.json`, blob);
    });

    const roster = getRoster(league);
    const dataString = JSON.stringify(roster);
    const blob = new Blob([dataString], {
        type: "text/json;charset=utf-8;",
    });
    zip.file(`roster.json`, blob);

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `madden-export-${v4()}.zip`);

    return true;
};
