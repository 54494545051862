import React, { FC } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { withFormik, Field, FormikProps } from "formik";
import * as Yup from "yup";

import "./style.css";

const fieldStyle = { width: "80%" };
const buttonStyle = { width: "96%", marginBottom: ".5rem", color: "white" };

const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .trim()
        .matches(
            /^[a-z0-9]+$/gm,
            "Usernames can only include alphanumeric characters."
        )
        .lowercase("Usernames must be lowercase.")
        .max(25, "Username is too long.")
        .required("Required"),
    password: Yup.string().min(6, "Too Short!").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
});

type State = {
    username: string;
    email: string;
    password: string;
};

type Props = {
    onSubmit: (values: State) => void;
};

const SignupForm: FC<FormikProps<State> & Props> = (props) => {
    const { errors, handleSubmit } = props;

    return (
        <form className="SignupForm" onSubmit={handleSubmit}>
            <Field
                as={TextField}
                name="username"
                type="username"
                label={"Username"}
                helperText={errors.username}
                error={!!errors.username}
                margin="normal"
                style={fieldStyle}
                required
            />
            <Field
                as={TextField}
                name="email"
                type="email"
                label={"Email"}
                helperText={errors.email}
                autoComplete="email"
                error={!!errors.email}
                margin="normal"
                style={fieldStyle}
                required
            />
            <Field
                as={TextField}
                id="password-input"
                name="password"
                type="password"
                label={"Password"}
                helperText={errors.password}
                error={!!errors.password}
                style={fieldStyle}
                autoComplete="current-password"
                margin="normal"
                required
            />
            <div className="SignupForm-Submit">
                <Button
                    className="signup-button"
                    variant="contained"
                    color="primary"
                    style={buttonStyle}
                    type="submit"
                >
                    Sign Up
                </Button>
            </div>
        </form>
    );
};

export default withFormik<Props, State>({
    validationSchema: SignupSchema,
    handleSubmit: (values, { props }) => {
        props.onSubmit(values);
    },
    displayName: "Signup Form",
})(SignupForm);
