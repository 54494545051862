export const firebase = {
    apiKey: "AIzaSyDrgZnBM0dqMi_IWXe1b3tCplX1HjyQc9k",
    authDomain: "madden-data.firebaseapp.com",
    databaseURL: "https://madden-data.firebaseio.com",
    storageBucket: "madden-data.appspot.com",
    messagingSenderId: "959188584157",
};

export const fbPaths = {
    userProfile: "/users",
};

const firebaseOptions = { firebase, fbPaths };
export default firebaseOptions;
