import React, { FunctionComponent } from "react";
import { FirebaseReducer, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import LoadingSpinner from "../../common/LoadingSpinner";
import styled from "styled-components";
import "firebase/auth";

type Props = {
    children: React.ReactNode;
};

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const AuthIsLoaded: FunctionComponent<Props> = (props) => {
    const auth = useSelector<RootState, FirebaseReducer.AuthState>(
        (state) => state.firebase.auth
    );

    console.log(auth);

    if (!isLoaded(auth)) {
        return (
            <LoadingContainer>
                <LoadingSpinner />
            </LoadingContainer>
        );
    }

    return <>{props.children}</>;
};

export default AuthIsLoaded;
