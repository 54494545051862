import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const Container = styled.div`
    justify-content: flex-start;
    padding-top: 7rem;
    height: 100%;
`;
const Progress = styled.div`
    align-items: center;
    height: 50%;
`;

const LoadingSpinner = ({ size }: { size: number }) => (
    <Container>
        <Progress>
            <CircularProgress variant="indeterminate" size={size || 80} />
        </Progress>
    </Container>
);

LoadingSpinner.defaultProps = {
    size: 80,
};

LoadingSpinner.propTypes = {
    size: PropTypes.number.isRequired,
};

export default LoadingSpinner;
