import {
    League,
    Player,
    PlayerStats,
    TeamStat,
    TeamStats,
} from "../../../types";
import * as _ from "underscore";
import { parse } from "json2csv";
import JSZip from "jszip";

const BLOB_TYPE = "data:text/csv;charset=utf-8,";

export const removeFalsy = (obj: any) => {
    let newObj: any = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop] !== null) {
            if (Array.isArray(obj[prop])) {
                newObj[prop] = _.without(obj[prop], null);
            } else {
                newObj[prop] = obj[prop];
            }
        }
    });
    return newObj;
};

export const getRoster = (league: League): Player[] => {
    const leagueRoster: Player[] = [];

    if (!hasRosters(league)) return leagueRoster;

    const rosters = Object.values(league?.teams || {}).map(
        (team) => team.roster
    );
    rosters.forEach((roster) => {
        const playerEntries = Object.entries(roster || {});
        playerEntries.forEach(([id, player]) => {
            leagueRoster.push(
                Object.assign(player, {
                    id,
                    team:
                        (league.teams &&
                            league.teams[player.teamId]?.teamName) ||
                        "",
                })
            );
        });
    });
    return leagueRoster;
};

export const flatten = (stats: any) => {
    return _.without(_.flatten(_.values(stats)), undefined);
};

export const hasRosters = (league: League) => {
    return league.teams && Object.values(league.teams)[0].roster;
};

function writeStats(
    stats: PlayerStats | TeamStats,
    teamFolder: JSZip,
    type: string
) {
    if (!stats) return;
    const statCSV = parse(type === "team" ? stats : Object.values(stats));
    teamFolder.file(
        `${type}-stats.csv`,
        new Blob([statCSV], { type: BLOB_TYPE })
    );
}

function getTeamName(league: League, teamStats: TeamStat): string {
    if (!league || !teamStats) return "Unknown";
    return (
        league?.teams?.[teamStats?.teamId]?.displayName ||
        teamStats?.teamId.toString() ||
        "Unknown"
    );
}

export function downloadStats(league: League, zip: JSZip) {
    if (!league.stats) return;
    const seasonType = Object.keys(league.stats);
    const statsFolder = zip.folder("stats");
    seasonType.forEach((season) => {
        // @ts-ignore
        const weeks = league?.stats[season] || {};
        const seasonFolder = statsFolder?.folder(season);

        Object.entries(weeks).forEach(([i, week]) => {
            if (!week) return;

            const weekFolder = seasonFolder?.folder(i);

            // @ts-ignore
            Object.values(week).forEach((team = {}) => {
                // @ts-ignore
                const teamStats = team["team-stats"];
                // @ts-ignore
                const playerStats = team["player-stats"];
                const teamName: string = getTeamName(league, teamStats);

                const teamFolder = weekFolder?.folder(teamName);
                if (teamFolder) {
                    writeStats(playerStats, teamFolder, "player");
                    writeStats(teamStats, teamFolder, "team");
                }
            });
        });
    });
}
