import React, { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { Divider, Modal } from "@mui/material";
import styled from "styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useSelector } from "react-redux";

import { HomeModalBody } from "./index";
import { Help, HelpTypes } from "../types";
import { Profile, RootState } from "../../../reducers";
import ImageModalBody from "./ImageModalBody";
import makeStyles from "@mui/styles/makeStyles";
import { info } from "../../../info";

const HelpIcon = styled(HelpOutlineIcon)`
    cursor: pointer;
`;

const HomeModal = styled(Modal)`
    display: flex;
    align-items: center;
`;

const useStyles = makeStyles({
    steps: {
        "& li": {
            wordWrap: "break-word",
        },
    },
});

const HelpContent: { [key: string]: Help } = {
    [HelpTypes.login]: {
        title: "Click the yellow login button.",
        image: require("../assets/login.png"),
    },
    [HelpTypes.selectLeague]: {
        title: "Select league to export.",
        image: require("../assets/select-league.png"),
    },
    [HelpTypes.exportLeague]: {
        title: "Click the export tab.",
        image: require("../assets/export-league.png"),
    },
    [HelpTypes.url]: {
        title: "Enter in the correct url.",
        image: require("../assets/url.png"),
    },
    [HelpTypes.export]: {
        title: "Choose what to export.",
        image: require("../assets/export.png"),
    },
};

const StepsToExport: FunctionComponent = () => {
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );
    const [open, setOpen] = useState(false);
    const [help, setHelp] = useState({ image: "", title: "" });
    const [openHelp, setOpenHelp] = useState(false);
    const classes = useStyles();

    const onClick = (helpType: HelpTypes) => {
        const help = HelpContent[helpType];
        setHelp(help);
        setOpenHelp(true);
    };

    return (
        <div>
            There are options to download the whole league as zipped json files
            or csv files.
            <h4>Steps to Export</h4>
            <ol className={classes.steps}>
                <li>
                    Please <Link to="/signup">sign up</Link> with a username.
                </li>
                <li className="app-install-step">
                    Open the Madden NFL {info.year} Companion on your phone.
                    <HelpIcon fontSize="small" onClick={() => setOpen(true)} />
                    <HomeModal open={open} onClose={() => setOpen(false)}>
                        <HomeModalBody />
                    </HomeModal>
                </li>
                <li>
                    Login into your EA Account.{" "}
                    <HelpIcon
                        fontSize="small"
                        onClick={() => onClick(HelpTypes.login)}
                    />
                </li>
                <li>
                    Select the league to export.{" "}
                    <HelpIcon
                        fontSize="small"
                        onClick={() => onClick(HelpTypes.selectLeague)}
                    />
                </li>
                <li>
                    Click Export on the bottom tab bar.{" "}
                    <HelpIcon
                        fontSize="small"
                        onClick={() => onClick(HelpTypes.exportLeague)}
                    />
                </li>
                <li>
                    Enter https://mxport.herokuapp.com/INSERT_USERNAME. Replace
                    INSERT_USERNAME with your own case-sensitive username. For
                    example, https://mxport.herokuapp.com/josh
                    <HelpIcon
                        fontSize="small"
                        onClick={() => onClick(HelpTypes.url)}
                    />
                </li>
                <li>
                    Choose what to export.
                    <HelpIcon
                        fontSize="small"
                        onClick={() => onClick(HelpTypes.export)}
                    />
                </li>
                <li>
                    Return to{" "}
                    <Link to={`/data/${profile?.username}`}>
                        https://maddenexporter.com
                    </Link>
                    .
                </li>
                <li>Download your data as a json or csv.</li>
            </ol>
            <Divider style={{ margin: "15px 0" }} />
            <HomeModal open={openHelp} onClose={() => setOpenHelp(false)}>
                <ImageModalBody title={help.title} image={help.image} />
            </HomeModal>
        </div>
    );
};

export default StepsToExport;
