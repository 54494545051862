import React, { FC, useState } from "react";
import { Field, FormikProps, withFormik } from "formik";
import { Button, Divider, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Alert from "@mui/material/Alert";
import * as Yup from "yup";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { loadStripe } from "@stripe/stripe-js";
import { createCheckoutSession } from "./api";

const UserSchema = Yup.object().shape({
    username: Yup.string()
        .trim()
        .matches(
            /^[a-z0-9]+$/gm,
            "Usernames can only include alphanumeric characters."
        )
        .lowercase("Usernames must be lowercase.")
        .max(25, "Username is too long.")
        .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
});

type Props = {
    user: {
        username: string;
        email: string;
        password?: string;
        role?: string;
    };
    onSubmit: (values: State) => void;
};

export type State = {
    username: string | undefined;
    email: string;
    password?: string;
};

const useStyles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
    },
    proBtn: {},
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const AccountForm: FC<FormikProps<State> & Props> = (props) => {
    const { errors, handleSubmit, values, user } = props;
    const [paymentError, setPaymentError] = useState(null);
    const classes = useStyles();

    const handleClick = async () => {
        const { sessionId }: any = await createCheckoutSession();
        const stripe = await stripePromise;
        const { error }: any = await stripe?.redirectToCheckout({
            sessionId,
        });

        if (error) {
            setPaymentError(error.message);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={classes.form}>
                <Field
                    as={TextField}
                    name="username"
                    type="text"
                    label="Username"
                    helperText={errors.username || ""}
                    error={!!errors.username}
                    margin="normal"
                    variant="outlined"
                    size="small"
                />
                <Field
                    as={TextField}
                    name="email"
                    type="email"
                    label="Email"
                    helperText={errors.email || ""}
                    error={!!errors.email}
                    margin="normal"
                    variant="outlined"
                    size="small"
                />
                {user.role !== "paid" && (
                    <Button
                        className={classes.proBtn}
                        variant="contained"
                        color="primary"
                        startIcon={<StarBorderIcon />}
                        onClick={handleClick}
                    >
                        Upgrade to a Pro Account
                    </Button>
                )}
                <Divider style={{ margin: "15px 0" }} />
                {paymentError && (
                    <Alert
                        style={{ marginBottom: "1rem" }}
                        variant="outlined"
                        severity="error"
                    >
                        {paymentError}
                    </Alert>
                )}
                {user.username !== values.username && (
                    <Alert
                        style={{ marginBottom: "1rem" }}
                        variant="outlined"
                        severity="warning"
                    >
                        Changing your username will delete your current data.
                    </Alert>
                )}
                <Button type="submit" variant="outlined">
                    Update
                </Button>
            </form>
        </>
    );
};

export default withFormik<Props, State>({
    validationSchema: UserSchema,
    mapPropsToValues: ({ user }) => {
        return { username: user.username || "", email: user.email };
    },
    handleSubmit: (values, { props }) => {
        props.onSubmit(values);
    },
    displayName: "Signup Form",
})(AccountForm);
