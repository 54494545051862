import React, { FC, useState } from "react";
import { JSONTree } from "react-json-tree";
import { saveAs } from "file-saver";
import { parse } from "json2csv";
import JSZip from "jszip";

import CsvSelection, { State } from "./CsvSelection";
import { getRoster, hasRosters, removeFalsy, downloadStats } from "./utils";
import { useFirebase } from "react-redux-firebase";
import { Team, League, Week, Roles } from "../../../types";
import { downloadJSON } from "./createFiles";
import { v4 as uuidv4 } from "uuid";
import { Profile } from "../../../reducers";
import { DataTree, EntryContainer, Button } from "./style";

type Props = {
    league: League;
    id: string;
    profile: Profile;
};

const Entry: FC<Props> = ({ league, id, profile }) => {
    const { username, role } = profile;
    const [showCsv, setShowCsv] = useState(false);
    const firebase = useFirebase();

    if (role !== Roles.PAID) {
        delete league.freeagents;
    }

    const onSubmit = (values: State) => {
        const zip = new JSZip();
        if (values.players) zip.file(`players.csv`, downloadRoster());
        if (values.teams) zip.file("teams.csv", downloadTeams());
        if (values.stats) downloadStats(league, zip);
        if (values.schedules) downloadSchedules(zip);
        if (values.freeagents) zip.file("freeagents.csv", downloadFreeAgents());

        // @ts-ignore
        global.analytics.track("CSV Download", {
            types: Object.entries(values)
                .filter((x) => x[1])
                .map((x) => x[0]),
        });

        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `madden-export-${uuidv4()}.zip`);
        });
    };

    const downloadRoster = () => {
        const roster = getRoster(league);
        const csv = parse([
            ...roster,
            ...Object.values(league?.freeagents || {}),
        ]);
        return new Blob([csv], { type: "data:text/csv;charset=utf-8," });
    };

    const downloadSchedules = (zip: JSZip) => {
        const seasons = Object.entries(league?.schedules || {});
        const schedulesFolder = zip.folder("schedules");
        seasons.forEach((season) => {
            const seasonType = season[0];
            const seasonFolder = schedulesFolder?.folder(seasonType);
            const weeks: [string, Week][] = Object.entries(
                removeFalsy(season[1])
            );
            for (let [week, games] of weeks) {
                seasonFolder?.file(`week-${week}.csv`, parse<Week>(games));
            }
        });
    };

    const downloadTeams = () => {
        const teams: Team[] = Object.values(league.teams || {});
        teams.forEach((team: Team) => {
            delete team.roster;
        });
        const teamCsv = parse(teams);
        return new Blob([teamCsv], { type: "data:text/csv;charset=utf-8," });
    };

    const downloadFreeAgents = () => {
        const freeAgentsCsv = parse(Object.values(league?.freeagents || {}));
        return new Blob([freeAgentsCsv], {
            type: "data:text/csv;charset=utf-8,",
        });
    };

    const onDelete = async () => {
        try {
            await firebase.remove(`/data/${username}/${id}`);
        } catch (e) {
            console.error(e);
        }
    };

    const getOptions = () => {
        const options = [];
        if (league) {
            if (hasRosters(league)) options.push("players");
            if (league.teams) options.push("teams");
            if (league.stats) options.push("stats");
            if (league.schedules) options.push("schedules");
            if (league.freeagents && role === Roles.PAID)
                options.push("freeagents");
        }
        return options;
    };

    return (
        <div>
            {league && (
                <EntryContainer>
                    <Button onClick={() => downloadJSON(league)}>
                        Download JSON
                    </Button>
                    <Button onClick={() => setShowCsv(!showCsv)}>
                        Select CSVs to Download
                    </Button>
                    <Button onClick={onDelete}>Delete</Button>
                    {showCsv && (
                        <CsvSelection
                            options={getOptions()}
                            onSubmit={onSubmit}
                        />
                    )}
                    <DataTree>
                        <JSONTree data={league} />
                    </DataTree>
                </EntryContainer>
            )}
        </div>
    );
};

export default Entry;
