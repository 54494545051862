import React, { FC } from "react";
import {
    FirebaseReducer,
    isEmpty,
    isLoaded,
    useFirebase,
} from "react-redux-firebase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Profile, RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { NavButton } from "./components";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router";

type Props = {
    toggleDrawer: (toggle: boolean) => void;
};

type NavItemProps = {
    title: string;
    Icon: any;
    to: string;
};

const NavItem: FC<NavItemProps> = ({ title, Icon, to }) => (
    <ListItem button>
        <ListItemIcon>
            <Icon />
        </ListItemIcon>
        <NavButton as={ListItemText} to={to}>
            {title}
        </NavButton>
    </ListItem>
);

const AuthMenu = () => {
    const firebase = useFirebase();
    const navigate = useNavigate();
    const handleLogout = async () => {
        await firebase.logout();
        navigate("/");
    };
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );
    return (
        <>
            <NavItem title="Home" Icon={HomeIcon} to="/" />
            <NavItem
                title="Madden Data"
                to={`/data/${profile.username}`}
                Icon={DescriptionIcon}
            />
            <NavItem title="Account" to="/account" Icon={AccountCircleIcon} />
            <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText className="small-nav-link sign-out">
                    Sign Out
                </ListItemText>
            </ListItem>
        </>
    );
};

const GuestMenu = () => (
    <ListItem button>
        <NavButton as={ListItemText} to="/login">
            Login
        </NavButton>
    </ListItem>
);

const SmallMenu: FC<Props> = ({ toggleDrawer }) => {
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );
    const auth = useSelector<RootState, FirebaseReducer.AuthState>(
        (state) => state.firebase.auth
    );
    const isLoggedIn = isLoaded(profile) && !isEmpty(auth);

    return (
        <div
            style={{ width: "auto" }}
            role="presentation"
            onClick={() => toggleDrawer(false)}
            onKeyDown={() => toggleDrawer(false)}
        >
            <List>{!isLoggedIn ? <GuestMenu /> : <AuthMenu />}</List>
        </div>
    );
};

export default SmallMenu;
