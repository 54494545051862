import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";

const useStyles = makeStyles<Theme>((theme) => ({
    paper: {
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "2rem 1rem",
        margin: "0 auto",
        borderRadius: theme.shape.borderRadius,
        textAlign: "center",
    },
    image: {
        maxHeight: 500,
        borderRadius: theme.shape.borderRadius,
    },
}));

type Props = {
    title: string;
    image: string;
};

const ImageModalBody: FunctionComponent<Props> = ({ title, image }) => {
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <h3>{title}</h3>
            <img className={classes.image} src={image} alt="helper image" />
        </div>
    );
};

export default ImageModalBody;
