import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import ReactPlayer from "react-player";
import { Theme } from "@mui/material";
import { info } from "../../../info";

const useStyles = makeStyles<Theme>((theme) => ({
    paper: {
        width: 800,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "2rem 1rem",
        margin: "0 auto",
        borderRadius: theme.shape.borderRadius,
    },
    videoBox: {
        position: "relative",
        paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
    },
    videoPlayer: {
        position: "absolute",
        top: 0,
        left: 0,
    },
}));

const HomeModalBody: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <h2>How to export from Madden NFL {info.year} Companion</h2>
            <div className={classes.videoBox}>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=wnRZvcYY5TM"
                    width="100%"
                    height="100%"
                    className={classes.videoPlayer}
                />
            </div>
        </div>
    );
};

export default HomeModalBody;
