import React, { FC } from "react";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import { Profile } from "../../../reducers";
import AccountMenu from "./AccountMenu";

type Props = {
    profile: Profile;
};

const AuthMenu: FC<Props> = ({ profile }) => {
    return (
        <>
            <Button>
                <NavLink
                    className="nav-link-styles"
                    color="inherit"
                    to={`/data/${profile.username}`}
                >
                    Madden Data
                </NavLink>
            </Button>
            <AccountMenu />
        </>
    );
};

export default AuthMenu;
