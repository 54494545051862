import React, { FC } from "react";

type AProps = {
    href: string;
    className?: string;
};

export const A: FC<AProps> = ({ href, className, children, ...rest }) => (
    <a
        className={className}
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        {...rest}
    >
        {children}
    </a>
);
