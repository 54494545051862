import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NavButton from "./NavButton";
import { useFirebase } from "react-redux-firebase";
import { useNavigate } from "react-router";

const AccountMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const firebase = useFirebase();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await firebase.logout();
        navigate("/");
        setAnchorEl(null);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <AccountCircleIcon htmlColor="white" />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <NavButton as={MenuItem} to="/account" onClick={handleClose}>
                    My Account
                </NavButton>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    );
};

export default AccountMenu;
