import { compose } from "redux";

type ExtraWindows = {
    devToolsExtension: typeof compose;
};

export enum Roles {
    PAID = "paid",
    FREE = "free",
}

export interface global {
    document: Document;
    window: Window & ExtraWindows;
    hot: {
        accept(path?: () => void, callback?: () => void): void;
    };
    analytics: {
        track: () => {};
    };
}

export type Leagues = {
    [key: string]: League;
};

export type League = {
    freeagents?: Roster;
    teams?: Teams;
    schedules?: Schedules;
    stats?: Stats;
};

export type Teams = {
    [key: string]: Team;
};

export type Team = {
    abbrName: string;
    awayLosses: number;
    awayTies: number;
    awayWins: number;
    calendarYear: number;
    capAvailable: number;
    capRoom: number;
    capSpent: number;
    cityName: string;
    confLosses: number;
    confTies: number;
    confWins: number;
    conferenceId: number;
    conferenceName: string;
    defPassYds: number;
    defPassYdsRank: number;
    defRushYds: number;
    defRushYdsRank: number;
    defScheme: number;
    defTotalYds: number;
    defTotalYdsRank: number;
    displayName: string;
    divLosses: number;
    divName: string;
    divTies: number;
    divWins: number;
    divisionId: number;
    divisionName: string;
    homeLosses: number;
    homeTies: number;
    homeWins: number;
    injuryCount: number;
    logoId: number;
    netPts: number;
    nickName: string;
    offPassYds: number;
    offPassYdsRank: number;
    offRushYds: number;
    offRushYdsRank: number;
    offScheme: number;
    offTotalYds: number;
    offTotalYdsRank: number;
    ovrRating: number;
    playoffStatus: number;
    prevRank: number;
    primaryColor: number;
    ptsAgainst: number;
    ptsAgainstRank: number;
    ptsFor: number;
    ptsForRank: number;
    rank: number;
    roster?: Roster;
    seasonIndex: number;
    secondaryColor: number;
    seed: number;
    stageIndex: number;
    tODiff: number;
    teamId: number;
    teamName: string;
    teamOvr: number;
    totalLosses: number;
    totalTies: number;
    totalWins: number;
    userName: string;
    weekIndex: number;
    winLossStreak: number;
    winPct: number;
};

export type Roster = {
    [key: string]: Player;
};

export type Player = {
    team?: string;
    id?: string;
    accelRating: number;
    age: number;
    agilityRating: number;
    awareRating: number;
    bCVRating: number;
    bigHitTrait: number;
    birthDay: number;
    birthMonth: number;
    birthYear: number;
    blockShedRating: number;
    breakSackRating: number;
    breakTackleRating: number;
    cITRating: number;
    capHit: number;
    capReleaseNetSavings: number;
    capReleasePenalty: number;
    carryRating: number;
    catchRating: number;
    clutchTrait: number;
    college: string;
    confRating: number;
    contractBonus: number;
    contractLength: number;
    contractSalary: number;
    contractYearsLeft: number;
    coverBallTrait: number;
    dLBullRushTrait: number;
    dLSpinTrait: number;
    dLSwimTrait: number;
    desiredBonus: number;
    desiredLength: number;
    desiredSalary: number;
    devTrait: number;
    draftPick: number;
    draftRound: number;
    dropOpenPassTrait: number;
    durabilityGrade: number;
    elusiveRating: number;
    experiencePoints: number;
    feetInBoundsTrait: number;
    fightForYardsTrait: number;
    finesseMovesRating: number;
    firstName: string;
    forcePassTrait: number;
    hPCatchTrait: number;
    height: number;
    highMotorTrait: number;
    hitPowerRating: number;
    homeState: number;
    homeTown: string;
    impactBlockRating: number;
    injuryLength: number;
    injuryRating: number;
    injuryType: number;
    intangibleGrade: number;
    isActive: boolean;
    isFreeAgent: boolean;
    isOnIR: boolean;
    isOnPracticeSquad: boolean;
    jerseyNum: number;
    jukeMoveRating: number;
    jumpRating: number;
    kickAccRating: number;
    kickPowerRating: number;
    kickRetRating: number;
    lBStyleTrait: number;
    lastName: string;
    leadBlockRating: number;
    legacyScore: number;
    manCoverRating: number;
    passBlockFinesseRating: number;
    passBlockPowerRating: number;
    passBlockRating: number;
    penaltyTrait: number;
    physicalGrade: number;
    playActionRating: number;
    playBallTrait: number;
    playRecRating: number;
    playerBestOvr: number;
    playerSchemeOvr: number;
    portraitId: number;
    posCatchTrait: number;
    position: string;
    powerMovesRating: number;
    predictTrait: number;
    presentationId: number;
    pressRating: number;
    productionGrade: number;
    pursuitRating: number;
    qBStyleTrait: number;
    reSignStatus: number;
    releaseRating: number;
    rookieYear: number;
    rosterId: number;
    routeRunDeepRating: number;
    routeRunMedRating: number;
    routeRunShortRating: number;
    runBlockFinesseRating: number;
    runBlockPowerRating: number;
    runBlockRating: number;
    runStyle: number;
    scheme: number;
    sensePressureTrait: number;
    sizeGrade: number;
    skillPoints: number;
    specCatchRating: number;
    speedRating: number;
    spinMoveRating: number;
    staminaRating: number;
    stiffArmRating: number;
    strengthRating: number;
    stripBallTrait: number;
    tackleRating: number;
    teamId: number;
    teamSchemeOvr: number;
    throwAccDeepRating: number;
    throwAccMidRating: number;
    throwAccRating: number;
    throwAccShortRating: number;
    throwAwayTrait: number;
    throwOnRunRating: number;
    throwPowerRating: number;
    throwUnderPressureRating: number;
    tightSpiralTrait: number;
    toughRating: number;
    truckRating: number;
    weight: number;
    yACCatchTrait: number;
    yearsPro: number;
    zoneCoverRating: number;
};

export enum WeekType {
    reg = "reg",
    pre = "pre",
    post = "post",
}

export type Game = {
    awayScore: number;
    awayTeamId: number;
    homeScore: number;
    homeTeamId: number;
    isGameOfTheWeek: boolean;
    scheduleId: number;
    seasonIndex: number;
    stageIndex: number;
    status: number;
    weekIndex: number;
};

export type Week = {
    [key: string]: Game;
};

export type Weeks = {
    [key: string]: Week;
};

export type Schedules = {
    [WeekType.pre]?: Weeks;
    [WeekType.reg]?: Weeks;
    [WeekType.post]?: Weeks;
};

export type PlayerStat = {
    fullName: string;
    puntAtt?: number;
    puntLongest?: number;
    puntNetYds?: number;
    puntNetYdsPerAtt?: number;
    puntTBs?: number;
    puntYds?: number;
    puntYdsPerAtt?: number;
    puntsBlocked?: number;
    puntsIn20?: number;
    rosterId: number;
    scheduleId: number;
    seasonIndex: number;
    stageIndex: number;
    statId: number;
    teamId: number;
    weekIndex: number;
    defCatchAllowed?: number;
    defDeflections?: number;
    defForcedFum?: number;
    defFumRec?: number;
    defIntReturnYds?: number;
    defInts?: number;
    defPts?: number;
    defSacks?: number;
    defSafeties?: number;
    defTDs?: number;
    defTotalTackles?: number;
    recCatchPct?: number;
    recCatches?: number;
    recDrops?: number;
    recLongest?: number;
    recPts?: number;
    recTDs?: number;
    recToPct?: number;
    recYacPerCatch?: number;
    recYds?: number;
    recYdsAfterCatch?: number;
    recYdsPerCatch?: number;
    recYdsPerGame?: number;
    passAtt?: number;
    passComp?: number;
    passCompPct?: number;
    passInts?: number;
    passLongest?: number;
    passPts?: number;
    passSacks?: number;
    passTDs?: number;
    passYds?: number;
    passYdsPerAtt?: number;
    passYdsPerGame?: number;
    passerRating?: number;
    rush20PlusYds?: number;
    rushAtt?: number;
    rushBrokenTackles?: number;
    rushFum?: number;
    rushLongest?: number;
    rushPts?: number;
    rushTDs?: number;
    rushToPct?: number;
    rushYds?: number;
    rushYdsAfterContact?: number;
    rushYdsPerAtt?: number;
    rushYdsPerGame?: number;
    fG50PlusAtt?: number;
    fG50PlusMade?: number;
    fGAtt?: number;
    fGCompPct?: number;
    fGLongest?: number;
    fGMade?: number;
    kickPts?: number;
    kickoffAtt?: number;
    kickoffTBs?: number;
    xPAtt?: number;
    xPCompPct?: number;
    xPMade?: number;
};

export type PlayerStats = {
    [key: string]: PlayerStat;
};

export type TeamStat = {
    defForcedFum: number;
    defFumRec: number;
    defIntsRec: number;
    defPassYds: number;
    defPtsPerGame: number;
    defRedZoneFGs: number;
    defRedZonePct: number;
    defRedZoneTDs: number;
    defRedZones: number;
    defRushYds: number;
    defSacks: number;
    defTotalYds: number;
    off1stDowns: number;
    off2PtAtt: number;
    off2PtConv: number;
    off2PtConvPct: number;
    off3rdDownAtt: number;
    off3rdDownConv: number;
    off3rdDownConvPct: number;
    off4thDownAtt: number;
    off4thDownConv: number;
    off4thDownConvPct: number;
    offFumLost: number;
    offIntsLost: number;
    offPassTDs: number;
    offPassYds: number;
    offPtsPerGame: number;
    offRedZoneFGs: number;
    offRedZonePct: number;
    offRedZoneTDs: number;
    offRedZones: number;
    offRushTDs: number;
    offRushYds: number;
    offSacks: number;
    offTotalYds: number;
    offTotalYdsGained: number;
    penalties: number;
    penaltyYds: number;
    scheduleId: number;
    seasonIndex: number;
    seed: number;
    stageIndex: number;
    statId: number;
    tODiff: number;
    tOGiveaways: number;
    tOTakeaways: number;
    teamId: number;
    totalLosses: number;
    totalTies: number;
    totalWins: number;
    weekIndex: number;
};

export type TeamStats = {
    [key: string]: TeamStat;
};

export type Stat = {
    "player-stats": PlayerStats;
    "team-stats": TeamStats;
};

export type StatWeek = {
    [key: string]: Stat;
};

export type StatsWeeks = {
    [key: string]: StatWeek;
};

export type Stats = {
    [WeekType.pre]?: StatsWeeks;
    [WeekType.reg]?: StatsWeeks;
    [WeekType.post]?: StatsWeeks;
};
