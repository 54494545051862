import { toQuery } from "react-responsive";

export const SmallQuery = toQuery({ maxWidth: 639 });
export const MediumQuery = toQuery({ minWidth: 640, maxWidth: 1023 });
export const MediumUpQuery = toQuery({ minWidth: 640 });
export const MediumPlusQuery = toQuery({ minWidth: 758, maxWidth: 1023 });
export const LargeQuery = toQuery({ minWidth: 1024, maxWidth: 1119 });
export const LargeUpQuery = toQuery({ minWidth: 1024 });
export const XlargeQuery = toQuery({ minWidth: 1200, maxWidth: 1399 });
export const XlargeUpQuery = toQuery({ minWidth: 1200 });
export const XXlargeQuery = toQuery({ minWidth: 1400 });
