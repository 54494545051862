import React, { useEffect } from "react";
import { isLoaded, useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Profile, RootState } from "../../../reducers";
import { CircularProgress, Paper } from "@mui/material";
import AccountForm, { State } from "./AccountForm";
import makeStyles from "@mui/styles/makeStyles";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import { useQuery } from "../../../utils";
import { fetchCheckoutSession } from "./api";
import { Roles } from "../../../types";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { SnackbarCloseReason } from "@mui/material/Snackbar/Snackbar";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

const useStyles = makeStyles({
    paper: {
        width: "100%",
        margin: "2rem auto",
        maxWidth: "500px",
        padding: "1rem",
    },
    header: {
        margin: "0.5rem 0",
    },
});

const Account = () => {
    const firebase = useFirebase();
    const classes = useStyles();
    const user = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );
    const [open, setOpen] = React.useState(false);
    const query = useQuery();
    const sessionId = query.get("session_id");

    useEffect(() => {
        if (sessionId) {
            fetchCheckoutSession(sessionId).then(() => {
                firebase.updateProfile({ role: "paid" });
                setOpen(true);
            });
        }
    }, [firebase, firebase.updateProfile, sessionId]);

    const onSubmit = async (values: State) => {
        if (values.username !== user.username) {
            await firebase.remove(`/data/${values.username}`);
            await firebase.updateProfile({ username: values.username });
        } else if (values.email !== user.email) {
            await firebase.updateEmail(values.email, true);
        }
    };

    const handleClose = (
        event?: React.SyntheticEvent<any> | Event,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    if (!isLoaded(user)) {
        return <CircularProgress variant="indeterminate" />;
    }

    return (
        <Paper className={classes.paper} elevation={3}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    Success, you are now a pro member!
                </Alert>
            </Snackbar>
            <h3 className={classes.header}>
                Account Settings {user.role === Roles.PAID && "[Pro Account]"}
            </h3>
            <Elements stripe={stripePromise}>
                <AccountForm user={user} onSubmit={onSubmit} />
            </Elements>
        </Paper>
    );
};

export default Account;
