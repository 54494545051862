import React, { FC } from "react";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import styledCmps from "styled-components";
import { Profile, RootState } from "../../reducers";
import AuthMenu from "./components/AuthMenu";
import { useSelector } from "react-redux";
import { FirebaseReducer, isEmpty, isLoaded } from "react-redux-firebase";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Roles } from "../../types";

const MenuContainer = styledCmps.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
`;

const FullMenu: FC = () => {
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );
    const auth = useSelector<RootState, FirebaseReducer.AuthState>(
        (state) => state.firebase.auth
    );
    const isLoggedIn = isLoaded(profile) && !isEmpty(auth);

    return (
        <MenuContainer>
            {!isLoggedIn && (
                <Button>
                    <NavLink
                        className="nav-link-styles login"
                        color="inherit"
                        to="/login"
                    >
                        Login
                    </NavLink>
                </Button>
            )}
            {isLoggedIn && (
                <Toolbar>
                    <div className="username">
                        {profile.username}
                        {profile.role === Roles.PAID && (
                            <StarBorderIcon fontSize="small" />
                        )}
                    </div>
                    <Button>
                        <NavLink
                            className="nav-link-styles"
                            color="inherit"
                            to="/"
                        >
                            Home
                        </NavLink>
                    </Button>
                    <AuthMenu profile={profile} />
                </Toolbar>
            )}
        </MenuContainer>
    );
};

export default FullMenu;
