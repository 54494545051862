export enum HelpTypes {
    login = "login",
    export = "export",
    exportLeague = "export-league",
    selectLeague = "select-league",
    url = "url",
}

export type Help = {
    title: string;
    image: string;
};
