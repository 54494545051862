import axios from "axios";

const BASE_URL = "https://us-central1-madden-data.cloudfunctions.net/charge/";

export const createCheckoutSession = async () => {
    try {
        const { data } = await axios.post(`${BASE_URL}create-checkout-session`);
        return data;
    } catch (e) {
        return e;
    }
};

export const fetchCheckoutSession = async (sessionId: string) => {
    try {
        const { data } = await axios(
            `${BASE_URL}checkout-session?sessionId=${sessionId}`
        );
        return data;
    } catch (e) {
        return e;
    }
};
