import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { CircularProgress, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { isLoaded, useFirebase } from "react-redux-firebase";
import makeStyles from "@mui/styles/makeStyles";

import SignupForm from "./signupform";
import "./style.css";
import { Profile, RootState } from "../../../reducers";
import { useNavigate } from "react-router";
import fb from "firebase/compat/app";
import "firebase/auth";

const useStyles = makeStyles({
    additionalInfo: {
        width: "35%",
        minWidth: 250,
        marginTop: "1rem",
    },
    link: {
        textDecoration: "none",
    },
});

type User = {
    email: string;
    password: string;
    username: string;
};

const Signup: FC = () => {
    const [isSnackOpen, setSnack] = useState(false);
    const authError = useSelector<RootState, fb.auth.AuthError>(
        (state) => state.firebase.authError
    );
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );
    const navigate = useNavigate();
    const firebase = useFirebase();
    const classes = useStyles();

    const onClose = () => {
        setSnack(false);
    };

    const handleSignup = async ({ email, password, username }: User) => {
        setSnack(true);
        try {
            await firebase.createUser({ email, password }, { username, email });
            navigate("/");
        } catch (e) {}
    };

    if (!isLoaded(profile)) {
        return (
            <div className="Signup">
                <div className="Signup-Progress">
                    <CircularProgress variant="indeterminate" />
                </div>
            </div>
        );
    }

    return (
        <div className="Signup">
            <Paper className="Signup-Panel">
                <SignupForm onSubmit={handleSignup} />
            </Paper>
            <Grid className={classes.additionalInfo} container>
                <Grid item>
                    <Link className={classes.link} to="/login">
                        Already have an account? Log In
                    </Link>
                </Grid>
            </Grid>
            {authError?.message && isSnackOpen ? (
                <Snackbar
                    open={!!authError?.message}
                    message={authError ? authError.message : "Signup error"}
                    action="close"
                    onClose={onClose}
                    autoHideDuration={3000}
                />
            ) : null}
        </div>
    );
};

export default Signup;
