import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";
import { isEmpty, FirebaseReducer } from "react-redux-firebase";
import { RootState } from "../../reducers";

type Props = {
    children: ReactNode;
};

const PrivateRoute: FC<Props> = ({ children }) => {
    const auth = useSelector<RootState, FirebaseReducer.AuthState>(
        (state) => state.firebase.auth
    );

    if (isEmpty(auth)) {
        return <Navigate to="/login" replace />;
    }

    return <>{children}</>;
};

export default PrivateRoute;
