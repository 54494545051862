import { combineReducers, Reducer } from "redux";
import { firebaseReducer, FirebaseReducer } from "react-redux-firebase";
import "firebase/compat/auth";
import { Leagues } from "../types";
import { RouterState } from "redux-first-history/build/es6/reducer";

export type Schema = {
    data: {
        [username: string]: Leagues;
    };
};

export type Profile = {
    username: string;
    email: string;
    role: string;
};

export type RootState = {
    firebase: FirebaseReducer.Reducer<Profile, Schema>;
    router: Reducer<RouterState>;
};

const createRootReducer = (routerReducer: Reducer<RouterState>) =>
    combineReducers({
        router: routerReducer,
        firebase: firebaseReducer,
    });

export default createRootReducer;
