import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useFirebase } from "react-redux-firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";

import { SIGNUP_PATH } from "../../../constants";
import * as fbAuth from "firebase/auth";

const useStyles = makeStyles({
    additionalInfo: {
        maxWidth: 360,
        width: "100%",
        marginTop: "1rem",
    },
    link: {
        textDecoration: "none",
    },
    login: {
        width: "35%",
        minWidth: 250,
    },
});

function LoginPage() {
    const firebase = useFirebase();
    const navigate = useNavigate();
    const classes = useStyles();

    return (
        <div className="login-page">
            <StyledFirebaseAuth
                className={classes.login}
                uiConfig={{
                    signInFlow: "popup",
                    signInSuccessUrl: "/",
                    signInOptions: [
                        {
                            provider: fbAuth.EmailAuthProvider.PROVIDER_ID,
                        },
                    ],
                    credentialHelper: "none",
                    callbacks: {
                        signInSuccessWithAuthResult: (
                            authResult,
                            redirectUrl
                        ) => {
                            firebase
                                // @ts-ignore
                                .handleRedirectResult(authResult)
                                .then(() => {
                                    navigate("/");
                                    // @ts-ignore
                                    global.analytics.track("Logged In", {
                                        email: authResult.user.email,
                                    });
                                });
                            return false;
                        },
                    },
                }}
                firebaseAuth={firebase.auth()}
            />
            <Grid className={classes.additionalInfo} container>
                <Grid item>
                    <Link className={classes.link} to={SIGNUP_PATH}>
                        Don't have an account? Sign Up
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}

export default LoginPage;
