import React from "react";
import { FormGroup, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

import "./CsvSelection.css";

type Props = {
    options: string[];
    onSubmit: (state: State) => void;
};

export type State = {
    players: boolean;
    teams: boolean;
    schedules: boolean;
    stats: boolean;
    [key: string]: boolean;
};

class CsvSelection extends React.Component<Props, State> {
    state = {
        players: false,
        teams: false,
        schedules: false,
        stats: false,
    };

    onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        this.props.onSubmit(this.state);
    };

    handleChange =
        (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ [name]: event?.target?.checked });
        };

    render() {
        const { options } = this.props;
        return (
            <form className="csv-form" onSubmit={this.onSubmit}>
                <h4 style={{ margin: 0 }}>Select the CSVs to download</h4>
                <div className="csv-options">
                    <FormGroup row>
                        {options.map((option) => (
                            <FormControlLabel
                                key={option}
                                control={
                                    <Checkbox
                                        // @ts-ignore
                                        checked={this.state[option]}
                                        onChange={this.handleChange(option)}
                                        value={option}
                                        color="primary"
                                    />
                                }
                                label={option}
                            />
                        ))}
                    </FormGroup>
                </div>
                <Button type="submit">
                    Download
                    <Icon>file_download</Icon>
                </Button>
            </form>
        );
    }
}

export default CsvSelection;
