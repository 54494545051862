import { FirebaseReducer, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Profile, RootState } from "../../../reducers";

const Footer = () => {
    const auth = useSelector<RootState, FirebaseReducer.AuthState>(
        (state) => state.firebase.auth
    );
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );

    if (isLoaded(profile, auth)) {
        // @ts-ignore
        global.analytics.identify(auth.uid, {
            username: profile.username,
            email: auth.email,
        });
    }
    return null;
};

export default Footer;
