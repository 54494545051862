import React, { FC } from "react";
import { Box, Button } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";

type Props = {
    hidden: boolean;
};

const useStyles = makeStyles({
    upgradeBtn: {
        textDecoration: "none",
    },
});

const ProAccount: FC<Props> = ({ hidden }) => {
    const classes = useStyles();
    return (
        <Box hidden={hidden}>
            <Alert severity="info">
                <AlertTitle>NEW: Upgrade to a Pro Account!</AlertTitle>
                Access exclusive features, priority support, and more soon. It
                supports future development!
                <Button color="primary">
                    <Link className={classes.upgradeBtn} to="/account">
                        Upgrade
                    </Link>
                </Button>
            </Alert>
        </Box>
    );
};

export default ProAccount;
