import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function DataLoading() {
    return (
        <div className="empty">
            <p>
                If there is an exported league, it will show up here.
                <br />
                Loading League Data...
            </p>
            <div className="loading-circle">
                <CircularProgress color="inherit" size={100} thickness={3} />
            </div>
        </div>
    );
}

export default DataLoading;
